import React from "react";
import { Container, Grid, Typography, Link, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import Mail from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#004aad",
    color: "#fff",
    padding: "20px 0",
  };

  const links = {
    textDecoration: "None",
    maxWidth: "250px",
    marginBottom: "10px",
    color: "#fff",
  };

  const sectionStyle = {
    marginBottom: "10px",
    fontWeight: "900",
    marginTop: "30px",
  };

  const socialContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const copyrightStyle = {
    textAlign: "center",
    marginTop: "60px",
  };

  return (
    <footer style={footerStyle}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" marginTop="50px" marginBottom="20px">
              FlyFin Consulting
            </Typography>
            <Typography variant="body1">
              Best Financial Advisor Around FlyFin Consulting is a dedicated
              supplier of Financial service to Financial professionals & Firms
              in Ireland, UK, Dubai, USA headquartered in Delhi, India, with a
              focus on delivering exceptional outsourcing services to our
              clients.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" style={sectionStyle}>
                  Site Links
                </Typography>
                <Grid container direction="column">
                  <RouterLink style={links} to="/about-us">
                    About Us
                  </RouterLink>
                  <RouterLink style={links} to="/professioals">
                    Professionals
                  </RouterLink>
                  <RouterLink style={links} to="/our-expertise">
                    Our Service
                  </RouterLink>
                  <RouterLink style={links} to="/contact-us">
                    Contact Us
                  </RouterLink>
                  <RouterLink
                    style={links}
                    to="/the-flyfin/privacy-policy-e358-420b-852d-0bfde033fe74"
                  >
                    Privacy Policy
                  </RouterLink>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" style={sectionStyle}>
                  Social
                </Typography>
                <div style={socialContainerStyle}>
                  <IconButton
                    href="https://www.facebook.com/people/Flyfin-Consultancy-Private-Limited/100094038165628/?sk=about"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon style={{ color: "#fff" }} />
                  </IconButton>
                  <IconButton
                    href="mailto:info@theflyfin.com"
                    target="_blank"
                    color="#fff"
                    rel="noopener noreferrer"
                  >
                    <Mail style={{ color: "#fff" }} />
                  </IconButton>
                  <IconButton
                    href="https://www.instagram.com/flyfinconsultancy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon style={{ color: "#fff" }} />
                  </IconButton>
                  <IconButton
                    href="https://www.linkedin.com/company/flyfin-consultancy-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon style={{ color: "#fff" }} />
                  </IconButton>
                </div>
                <Typography mt={2} variant="body2" gutterBottom>
                  info@theflyfin.com
                </Typography>
                <Typography mt={2} variant="body1" gutterBottom>
                  +91-9311357594
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" style={sectionStyle}>
                  Reach Us :
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Headquarters (Bharat):
                  <br />
                  Shop no 67, Basement, sector 10 market,
                  <br />
                  Faridabad, 121006
                </Typography>
                <Typography variant="body2" gutterBottom>
                  UK Office:
                  <br />1 Gibson drive, Bracknell, Berkshire
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="body2" style={copyrightStyle}>
          &copy; {new Date().getFullYear()} FlyFin Consulting
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
