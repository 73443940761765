import React, { Suspense } from "react";

import founimg from "../../assets/images/professionals/founder.png";
import founimg2 from "../../assets/images/professionals/pooja-sharma1.jpg";
// import Team from "../../Components/Team";
// import member1 from "../../assets/images/professionals/member1.png";
// import member2 from "../../assets/images/professionals/member2.png";
// import member3 from "../../assets/images/professionals/member3.jpg";
// import member4 from "../../assets/images/professionals/member4.jpg";
// import member5 from "../../assets/images/professionals/member5.jpg";
// import member6 from "../../assets/images/professionals/member6.jpeg";

const AboutUs = React.lazy(() => import("../../Components/Aboutus"));
const Founder = React.lazy(() => import("../../Components/Founder"));
const Footer = React.lazy(() => import("../../footer"));

const founderData = {
  image: founimg,
  name: "Abhishek Vashisht",
  title: "Director (CPA | CA)",
  description:
    "CPA|CA Abhishek Vashisht is a Chartered Accountant with over 12 years of experience. He has completed CPA Ireland, Diploma in IFRS from ACCA (UK), a certified course in Forensic Audit and Fraud Detection, and a certified course in Public Finance and Government Accounting. He has worked across multiple industries including telecommunication, media and entertainment, finance and banking, and FMCG, providing tax consultancy, audit, and assurance services to corporates. His dedication to social welfare is reflected in his active participation in child welfare and social initiatives through his NGO, V Support Foundation. His passion for making a positive impact on society drives him to contribute to various causes related to child welfare and community development. His expertise, combined with his commitment to financial excellence and social responsibility, makes him a valuable asset in the field of finance and accounting.",
};

const founderData2 = {
  image: founimg2,
  name: "Mrs. Pooja Sharma",
  title: "Co-Director",
  description:
    "Mrs. Pooja Sharma is an accomplished professional with a strong educational background and extensive experience. With an MBA in Business Analytics, she has honed her analytical skills to excel in various fields. Additionally, she has a background as a semi-qualified Chartered Accountant, contributing valuable expertise gained from the outsourcing industry. With over 6 years of professional experience, her journey has been marked by success and dedication. Currently, she plays a key role in handling and managing her organization alongside her husband, showcasing her leadership and collaborative abilities. Through her academic achievements and hands-on experience, she exemplifies a well-rounded professional who brings a unique blend of business acumen and financial expertise to her endeavors. Her commitment to excellence and partnership in managing the organization demonstrates her ability to thrive in dynamic and challenging environments.",
};

// const teamMembers = [
//     {
//       image: member1,
//       name: 'Rishika Dogra',
//       title: 'Associate advocate',
//       description: 'Advocate Rishika have  extensive experience in handling corporate and civil cases before various Courts & Tribunals including Delhi High Court & Supreme Court on matters relating to Arbitration Law, Company Law, Civil Law, Contractual issues, intellectual property and Consumer disputes, companies law, and provide Financial assistance  to  women entrepreneur start-ups.',
//     },
//     {
//       image: member2,
//       name: 'Advocate Megha',
//       title: 'Associate Advocate',
//       description: 'Advocate Megha, skilled in diverse Financial arenas, navigates Courts like Delhi High Court and Supreme Court. Proficient in Arbitration, Company, Civil, Contract, IP, and Consumer Law, she champions women entrepreneurs, providing bespoke Financial aid. Ensuring their triumph over Financial hurdles, she fosters their growth and prosperity in business ventures.',
//     },
//     {
//       image: member3,
//       name: 'Vaibhav Sood',
//       title: 'Associate Advocate',
//       description: 'Advocate Vaibhav Sood, esteemed for his expertise in criminal defense, adeptly navigates Financial complexities. With precision strategy and compelling courtroom advocacy, he ensures clients rights are vigorously upheld, striving for justice. His meticulous counsel empowers clients, guiding them through challenging Financial terrain with confidence and determination.',
//     },
//     {
//       image: member4,
//       name: 'Hitesh Solanki',
//       title: 'Associate Advocate',
//       description: 'Hitesh Solanki, a 2020 law graduate from Guru Gobind Singh Indraprastha University, embarked on his Financial journey during the lockdown by pursuing online judicial services classes. Despite parental expectations, his passion for criminal advocacy led him to enroll with the Bar Council of India in 2021. Currently, he passionately practices across all districts and the High Court of Delhi.',
//     },
//     {
//       image: member5,
//       name: 'Advocate Mehul Malhotra',
//       title: 'Associate Advocate',
//       description: 'Mehul Malhotra is an Advocate practicing in Delhi High Court with extensive experience in civil, criminal, divorce, maintenance, accident claims, and debt recovery cases. He handles Writ Petitions and Special Leave Petitions, delivering exceptional Financial services and achieving favorable outcomes. Advocate Mehul is dedicated to justice and advocating his clients best interests.',
//     },
//     {
//       image: member6,
//       name: 'Advocate Hiteshi Kakkar',
//       title: 'Associate Advocate',
//       description: 'Advocate Hiteshi Kakkar is a Financial professional with 5-6 years of extensive practice in High Courts, Tribunals, and the Supreme Court of India. Her versatile expertise spans multiple Financial areas, providing comprehensive solutions. Committed to high-quality representation and positive outcomes, her dedication to justice and meticulous attention to detail make her a trusted advocate.',
//     },
//   ];

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AboutUs pageTitle="Professionals" />
      <Founder
        image={founderData.image}
        name={founderData.name}
        title={founderData.title}
        description={founderData.description}
        imagePosition="left"
      />
      <Founder
        image={founderData2.image}
        name={founderData2.name}
        title={founderData2.title}
        description={founderData2.description}
        imagePosition="right"
      />
      {/* <Team members={teamMembers} /> */}
      <Footer />
    </Suspense>
  );
}

export default App;
